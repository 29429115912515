@font-face {
  font-family: "Helvetica Neue";
  src: url("../fonts/HelveticaNeue-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("../fonts/Helvetica-Neu-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Lulo Clean";
  src: url("../fonts/Lulo-Clean-One-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
