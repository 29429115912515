.section-col-start-2:nth-of-type(2n) {
  border-image: conic-gradient(#fafafa 0 0) fill 0 / / 0 100vw;
}

.section-col-start-2:nth-of-type(odd) {
  border-image: conic-gradient(#fff 0 0) fill 0 / / 0 100vw;
}

@font-face {
  font-family: Helvetica Neue;
  src: url("HelveticaNeue-Light.bff6644d.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Helvetica Neue;
  src: url("Helvetica-Neu-Bold.d1e600e3.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Lulo Clean;
  src: url("Lulo-Clean-One-Bold.4e6a61d9.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

a {
  color: #e8a253e6;
  text-underline-offset: 4px;
  font-family: Helvetica Neue, sans-serif;
  font-weight: 300;
  text-decoration-line: underline;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

a:hover {
  color: #262523e6;
}

h1, h3, h2 {
  letter-spacing: -.1rem;
  color: #262523e6;
  font-family: Lulo Clean, sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
}

p, li {
  color: #262523e6;
  font-family: Helvetica Neue, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  line-height: 2rem;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.right-4 {
  right: 1rem;
}

.top-0 {
  top: 0;
}

.top-1\/2 {
  top: 50%;
}

.top-12 {
  top: 3rem;
}

.top-2 {
  top: .5rem;
}

.z-10 {
  z-index: 10;
}

.col-span-full {
  grid-column: 1 / -1;
}

.box-border {
  box-sizing: border-box;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-24 {
  height: 6rem;
}

.h-\[80vh\] {
  height: 80vh;
}

.w-full {
  width: 100%;
}

.max-w-\[60ch\] {
  max-width: 60ch;
}

.max-w-\[calc\(100\%-2rem\)\] {
  max-width: calc(100% - 2rem);
}

.max-w-full {
  max-width: 100%;
}

.grow {
  flex-grow: 1;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.resize {
  resize: both;
}

.list-inside {
  list-style-position: inside;
}

.list-disc {
  list-style-type: disc;
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-7 {
  gap: 1.75rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-y-10 {
  row-gap: 2.5rem;
}

.gap-y-6 {
  row-gap: 1.5rem;
}

.gap-y-8 {
  row-gap: 2rem;
}

.self-center {
  align-self: center;
}

.justify-self-center {
  justify-self: center;
}

.overflow-hidden {
  overflow: hidden;
}

.border {
  border-width: 1px;
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.border-custom-gray {
  border-color: #262523e6;
}

.bg-custom-light-grey {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-opacity-75 {
  --tw-bg-opacity: .75;
}

.fill-custom-gray {
  fill: #262523e6;
}

.object-contain {
  object-fit: contain;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pt-44 {
  padding-top: 11rem;
}

.font-helvetica-neue {
  font-family: Helvetica Neue, sans-serif;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.leading-9 {
  line-height: 2.625rem;
}

.text-custom-gray {
  color: #262523e6;
}

.text-custom-orange {
  color: #e8a253e6;
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.no-underline {
  text-decoration-line: none;
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.grid-template {
  grid-template-columns: 1fr min(60ch, 100% - 2rem) 1fr;
}

.section-col-start-2 > :not(.col-span-full) {
  grid-column-start: 2;
}

.open-nav-overlay {
  z-index: 10;
  box-sizing: border-box;
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  height: calc(100vh - 5.5rem);
  padding-bottom: 5.5rem;
  font-size: 1.5rem;
  line-height: 3rem;
  display: flex;
  position: fixed;
  top: 6rem;
  left: 0;
  right: 0;
}

.hover\:fill-custom-orange:hover {
  fill: #e8a253e6;
}

.hover\:text-custom-gray:hover {
  color: #262523e6;
}

.hover\:text-custom-orange:hover {
  color: #e8a253e6;
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-custom-orange:focus {
  --tw-ring-color: #e8a253e6;
}

@media (width >= 640px) {
  .sm\:max-w-\[30\%\] {
    max-width: 30%;
  }

  .sm\:max-w-\[70\%\] {
    max-width: 70%;
  }
}

@media (width >= 768px) {
  .ipad-mini\:flex {
    display: flex;
  }

  .ipad-mini\:hidden {
    display: none;
  }

  .ipad-mini\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .ipad-mini\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

@media (width >= 1024px) {
  .desktop\:order-2 {
    order: 2;
  }

  .desktop\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .desktop\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .desktop\:col-start-5 {
    grid-column-start: 5;
  }

  .desktop\:col-end-\[-1\] {
    grid-column-end: -1;
  }

  .desktop\:w-1\/2 {
    width: 50%;
  }

  .desktop\:flex-row {
    flex-direction: row;
  }

  .desktop\:items-start {
    align-items: flex-start;
  }

  .desktop\:gap-0 {
    gap: 0;
  }

  .desktop\:gap-x-8 {
    column-gap: 2rem;
  }

  .desktop\:px-\[10vw\] {
    padding-left: 10vw;
    padding-right: 10vw;
  }

  .desktop\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .desktop\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

@media (width >= 1280px) {
  .xl\:max-w-\[70\%\] {
    max-width: 70%;
  }
}
/*# sourceMappingURL=index.5f6175b0.css.map */
