@import url("./styles/custom.css");
@import url("./styles/fonts.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  a {
    @apply font-helvetica-neue font-light text-custom-orange underline underline-offset-4 transition-colors duration-200 ease-in-out hover:text-custom-gray;
  }

  h1,
  h3 {
    @apply font-lulo-clean text-base -tracking-1 text-custom-gray;
  }

  h2 {
    @apply font-lulo-clean text-base -tracking-1 text-custom-gray;
  }

  p {
    @apply font-helvetica-neue text-custom-xl font-light leading-8 text-custom-gray;
  }

  li {
    @apply font-helvetica-neue text-custom-xl font-light leading-8 text-custom-gray;
  }
}

@layer utilities {
  .grid-template {
    grid-template-columns: 1fr min(60ch, calc(100% - 2rem)) 1fr;
    /* We use the `min` helper to pick whichever value winds up being smaller. On large screens, it will take up `65ch` width. 
    On smaller screens, where there isn't enough horizontal space for 65 characters, 
    it is clamped to 100%–2rem (for 1rem padding) of the available container width. */
  }

  /* Puts all children of section-col-start-2 on grid-column: 2 except for elements with .col-span-full – fullbleed images. */
  .section-col-start-2 > *:not(.col-span-full) {
    @apply col-start-2;
  }

  .open-nav-overlay {
    @apply fixed left-0 right-0 top-24 z-10 box-border flex h-[calc(100vh-5.5rem)] flex-col items-center justify-center gap-8 bg-gray-50 pb-[5.5rem] text-2xl leading-[3rem];
  }
}
